// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

.checkbox-variant(@color; @background; @border) {

  input[type="checkbox"]:checked + label::before {
    background-color: @background;
    border-color: @border;
  }

  input[type="checkbox"]:checked + label::after {
    color: @color;
  }


  &.checkbox-inversed {
    input[type="checkbox"]:checked + label::before {
      background-color: @color;
      border-color: @border;
    }

    input[type="checkbox"]:checked + label::after {
      color: @background;
    }
  }

}
