// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

.radio-variant(@color; @background; @border) {

  input[type="radio"] + label::after {
    border-color: @background;
  }

  input[type="radio"]:checked + label::before {
    border-color: @border;
  }

  input[type="radio"]:checked + label::after {
    background-color: @background;
  }

}
