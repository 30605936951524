/* http://bootsnipp.com/snippets/ZkMKE */

.checkbox {
  padding-left: 20px;

  label {
    display: inline-block;
    position: relative;
    padding-left: 5px;

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #cccccc;
      border-radius: @border-radius-base;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    }

    &::after {
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 0;
      margin-left: -20px;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #555555;
    }
  }

  input[type="checkbox"] {
    opacity: 0;
    outline: 0;

    &:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }

    &:checked + label::after {
      font-family: 'FontAwesome';
      content: "\f00c";
    }

    &:disabled + label {
      opacity: 0.65;
      cursor: not-allowed;

      &::before {
        background-color: #eeeeee;
        cursor: not-allowed;
      }
    }
  }

  &.checkbox-circle label::before {
    border-radius: 50%;
  }
  &.checkbox-inline {
    margin-top: 0;
  }
}


// Alternate checkboxes
// --------------------------------------------------

.checkbox-default {
  .checkbox-variant(@checkbox-default-color, @checkbox-default-background, @checkbox-default-border);
}
.checkbox-primary {
  .checkbox-variant(@checkbox-primary-color, @checkbox-primary-background, @checkbox-primary-border);
}
.checkbox-success {
  .checkbox-variant(@checkbox-success-color, @checkbox-success-background, @checkbox-success-border);
}
.checkbox-info {
  .checkbox-variant(@checkbox-info-color, @checkbox-info-background, @checkbox-info-border);
}
.checkbox-warning {
  .checkbox-variant(@checkbox-warning-color, @checkbox-warning-background, @checkbox-warning-border);
}
.checkbox-danger {
  .checkbox-variant(@checkbox-danger-color, @checkbox-danger-background, @checkbox-danger-border);
}

