// Modals

.modal-variant(@header-bg-color; @header-fg-color) {
  .modal-content {
    .modal-header {
      color: @header-fg-color;
      background-color: @header-bg-color;
    }
  }
}
