/* Side notes for calling out things
-------------------------------------------------- */

/* Base styles (regardless of theme) */

.callout {
  margin: 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  code, .highlight {
    background-color: #fff;
  }
}

.callout-success {
  .callout-variant(@callout-success-bg; @callout-success-border; @callout-success-text);
}

.callout-info {
  .callout-variant(@callout-info-bg; @callout-info-border; @callout-info-text);
}

.callout-warning {
  .callout-variant(@callout-warning-bg; @callout-warning-border; @callout-warning-text);
}

.callout-danger {
  .callout-variant(@callout-danger-bg; @callout-danger-border; @callout-danger-text);
}

.callout-default {
  .callout-variant(@callout-default-bg; @callout-default-border; @callout-default-text);
}

.callout-primary {
  .callout-variant(@callout-primary-bg; @callout-primary-border; @callout-primary-text);
}
