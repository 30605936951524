// Callouts

.callout-variant(@background; @border; @text-color) {
  background-color: @background;
  border-color: @border;

  h1, h2, h3, h4, h5, h6 {
    color: @text-color;
  }

  a {
    font-weight: bold;
    color: @text-color;
  }
}
