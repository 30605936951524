/* http://bootsnipp.com/snippets/ZkMKE */

.radio {
  padding-left: 20px;

  label {
    display: inline-block;
    position: relative;
    padding-left: 5px;

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #cccccc;
      border-radius: 50%;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out;
      -o-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out;
    }

    &::after {
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 3px;
      top: 3px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: #555555;
      -webkit-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    }
  }

  input[type="radio"] {
    opacity: 0;

    &:focus + label::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }

    &:checked + label::after {
      -webkit-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1);
    }

    &:disabled + label {
      opacity: 0.65;
      cursor: not-allowed;

      &::before {
        cursor: not-allowed;
      }
    }
  }

  &.radio-inline {
    margin-top: 0;
  }
}


// Alternate radio buttons
// --------------------------------------------------

.radio-default {
  .radio-variant(@radio-default-color, @radio-default-background, @radio-default-border);
}
.radio-primary {
  .radio-variant(@radio-primary-color, @radio-primary-background, @radio-primary-border);
}
.radio-success {
  .radio-variant(@radio-success-color, @radio-success-background, @radio-success-border);
}
.radio-info {
  .radio-variant(@radio-info-color, @radio-info-background, @radio-info-border);
}
.radio-warning {
  .radio-variant(@radio-warning-color, @radio-warning-background, @radio-warning-border);
}
.radio-danger {
  .radio-variant(@radio-danger-color, @radio-danger-background, @radio-danger-border);
}

